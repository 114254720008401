import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BottomNavBar from "./Layout/BottomNavBar";
import Header from "./Layout/Header";
import WorkoutsCarousel from "./Carousels/WorkoutsCarousel";
import ClassesCarousel from "./Carousels/ClassesCarousel";

const Homepage = () => {
  const { gym_id } = useParams();
  const [homepageData, setHomepageData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!gym_id) {
      console.error("Error: gym_id is undefined or missing!");
      setError("Invalid gym selection. Please go back and select a valid gym.");
      return;
    }

    const fetchHomepageData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("Authentication failed. Please log in again.");
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}homepage/${gym_id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (!response.ok) {
          setError(
            `Failed to fetch data for the selected gym. Status: ${response.status}`
          );
          return;
        }

        const data = await response.json();
        setHomepageData(data);
      } catch (error) {
        setError("An error occurred while fetching the homepage data.");
      }
    };

    fetchHomepageData();
  }, [gym_id]);

  if (error) {
    return (
      <div className="text-center mt-10">
        <h2 className="text-red-500">{error}</h2>
      </div>
    );
  }

  if (!homepageData) {
    return (
      <div className="text-center mt-10">
        <h2>Loading homepage data...</h2>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header Section */}
      <Header
        memberName={homepageData.member_details.first_name} // Pass first name
        memberPhoto={homepageData.member_details.profile_photo_path} // Profile photo
        gymName={homepageData.gym.gym_name} // Gym name
        gymBackground={homepageData.gym.profile_gym_image_path} // Gym background
      />

      {/* Main Content Section */}
      <div className="px-4 space-y-6">
        {/* Workouts Section */}
        <div>
          <WorkoutsCarousel workouts={homepageData.workouts} />
        </div>

        {/* Classes Section */}
        <div>
          <h2 className="text-xl font-bold text-gray-800 mb-4">Classes</h2>
          <ClassesCarousel classes={homepageData.classes} />
        </div>    
      </div>

      {/* Bottom Navigation */}
      <BottomNavBar />
    </div>
  );
};

export default Homepage;