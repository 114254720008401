import React from "react";

const Header = ({ memberName, memberPhoto, gymName, gymBackground }) => {
  return (
    <div
      className="relative w-full h-40 md:h-48 bg-cover bg-center flex items-center px-6"
      style={{
        backgroundImage: `url(${gymBackground || "https://via.placeholder.com/600"})`,
      }}
    >
      {/* Semi-transparent overlay for text readability */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900 opacity-80"></div>

      {/* Text Content */}
      <div className="relative z-10 text-white">
        <h1 className="text-2xl md:text-3xl font-bold">
          Welcome back,{" "}
          <span className="capitalize text-orange-400">
            {memberName || "Member"}
          </span>
          !
        </h1>
        <p className="text-lg mt-1">
          You are currently at{" "}
          <span className="text-orange-300 font-semibold">
            {gymName || "Your Gym"}
          </span>
          .
        </p>
      </div>

      {/* Member Profile Photo */}
      <div className="relative z-10 ml-auto">
        <img
          src={
            memberPhoto ||
            "https://via.placeholder.com/150"
          }
          alt="Member Profile"
          className="w-16 h-16 md:w-20 md:h-20 rounded-full border-2 border-white shadow-md"
        />
      </div>
    </div>
  );
};

export default Header;