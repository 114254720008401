import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import GymCard from "./GymCard";

const GymList = ({ gyms: initialGyms }) => {
  const [gyms, setGyms] = useState([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    // Fetch gyms from localStorage if not passed in props
    if (!initialGyms || initialGyms.length === 0) {
      const localGyms = localStorage.getItem("gyms");

      try {
        const parsedGyms = localGyms ? JSON.parse(localGyms) : [];
        setGyms(parsedGyms);
      } catch (error) {
        console.error("Failed to parse gyms from localStorage:", error);
        setGyms([]);
      }
    } else {
      // Handle gyms passed as props
      try {
        const parsedGyms =
          typeof initialGyms === "string" ? JSON.parse(initialGyms) : initialGyms;
        setGyms(parsedGyms || []);
      } catch (error) {
        console.error("Failed to parse gyms from props:", error);
        setGyms([]);
      }
    }
  }, [initialGyms]);

  // Handle gym selection and navigate to Homepage
  const handleGymSelect = (gymId) => {
    // Store selected gym in localStorage
    const selectedGym = gyms.find((gym) => gym.gym_id === gymId);
    if (selectedGym) {
      localStorage.setItem("selected_gym", JSON.stringify(selectedGym));
      navigate(`/homepage/${gymId}`); // Redirect to Homepage with gymId
    }
  };

  if (!gyms || gyms.length === 0) {
    return (
      <div className="gym-list-container p-4 text-center">
        <h1 className="text-2xl font-bold mb-4">No Gyms Found</h1>
        <p className="text-gray-600">
          It seems you are not associated with any gym. Please contact support for assistance.
        </p>
      </div>
    );
  }

  return (
    <div className="gym-list-container p-4">
      <h1 className="text-2xl font-bold text-center mb-6">Select your Gym</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {gyms.map((gym) => (
          <GymCard key={gym.gym_id} gym={gym} onGymSelect={handleGymSelect} />
        ))}
      </div>
    </div>
  );
};

export default GymList;