import React from "react";

const WorkoutsCarousel = ({ workouts }) => {
  return (
    <div className="p-4"> {/* Removed mt-[80px] */}
      {/* Title */}
      <h2 className="text-xl font-bold text-gray-800 mb-4">Workouts</h2>
      
      {/* Workout List with Vertical Scrolling */}
      {workouts && workouts.length > 0 ? (
        <div
          className="h-[400px] overflow-y-auto space-y-4 p-2"
          style={{ scrollbarWidth: "thin" }} 
        >
          {workouts.map((workout) => (
            <div
              key={workout.id}
              className="relative h-[150px] rounded-lg shadow-md bg-cover bg-center"
              style={{
                backgroundImage: `url(${workout.profile_photo_path || "https://via.placeholder.com/300"})`,
              }}
            >
              {/* Workout Details Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex flex-col justify-end p-4 text-white">
                <h3 className="text-lg font-semibold truncate">{workout.name}</h3>
                <p className="text-sm line-clamp-2">
                  {workout.description || "No description provided"}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center">No workouts available today.</p>
      )}
    </div>
  );
};

export default WorkoutsCarousel;