import React from "react";

const ClassesCarousel = ({ classes }) => {
  return (
    <div className="p-4">
      {/* Title */}
      {/* Scrollable Classes Container */}
      <div
        className="h-[400px] overflow-y-auto grid grid-cols-2 gap-4 pb-20"
        style={{ scrollbarWidth: "thin", WebkitOverflowScrolling: "touch" }} // Optional for smooth scrolling
      >
        {classes && classes.length > 0 ? (
          classes.map((cls) => (
            <div
              key={cls.id}
              className="relative h-[180px] bg-gray-300 rounded-lg shadow-md overflow-hidden"
            >
              {/* Background Image */}
              <img
                src={cls.profile_photo_path || "https://via.placeholder.com/300"}
                alt={cls.class_name}
                className="absolute top-0 left-0 w-full h-full object-cover"
              />

              {/* Content Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-2 text-white">
                {/* Class Name */}
                <h3 className="text-lg font-semibold truncate">{cls.class_name}</h3>

                {/* Class Description */}
                <p className="text-sm truncate">{cls.class_description}</p>

                {/* Schedules */}
                <div className="mt-2 text-xs">
                  {cls.schedules && cls.schedules.length > 0 ? (
                    cls.schedules.map((schedule, index) => (
                      <p key={index} className="truncate">
                        {schedule.day_of_week}:{" "}
                        {`${schedule.start_time.slice(0, 5)} - ${schedule.end_time.slice(0, 5)}`}
                      </p>
                    ))
                  ) : (
                    <p>No schedule available</p>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center col-span-2">
            No classes available.
          </p>
        )}
      </div>
    </div>
  );
};

export default ClassesCarousel;