import React, { useEffect, useState } from "react";
import MapComponent from "../Map/map";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SalesDashboard = () => {
  const [gyms, setGyms] = useState([]);
  const [validGyms, setValidGyms] = useState([]);
  const [unlinkedOwners, setUnlinkedOwners] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  // Fetch gym data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}sales/dashboard/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const allGyms = response.data.gyms;
        const filteredGyms = allGyms.filter(
          (gym) => gym.gym_latitude && gym.gym_longitude
        );

        setGyms(allGyms);
        setValidGyms(filteredGyms);
        setUnlinkedOwners(response.data.unlinked_gym_owners);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [API_URL, token]);

  // Logout Function
  const handleLogout = async () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      try {
        await axios.post(
          `${API_URL}users/auth/logout/`,
          {},
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        localStorage.removeItem("token");
        navigate("/login");
      } catch (err) {
        console.error("Error logging out:", err);
        alert("An error occurred while logging out.");
      }
    }
  };

  return (
    <div className="p-6">
      {/* Header with Logout Button */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Sales Dashboard</h1>
        <button
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md shadow-md"
        >
          Logout
        </button>
      </div>

      {/* Map View */}
      <div className="mb-6">
        <MapComponent gyms={validGyms} />
      </div>

      {/* Gym Sales Data */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <h2 className="text-xl font-bold mb-4">Gym Sales Data</h2>
        <div className="max-h-64 overflow-y-auto">
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="border px-4 py-2">Gym Name</th>
                <th className="border px-4 py-2">No. of Members</th>
                <th className="border px-4 py-2">No. of Packages</th>
                <th className="border px-4 py-2">No. of Transactions</th>
                <th className="border px-4 py-2">Gym Existence</th>
              </tr>
            </thead>
            <tbody>
              {gyms.map((gym, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{gym.gym_name}</td>
                  <td className="border px-4 py-2">{gym.number_of_members}</td>
                  <td className="border px-4 py-2">{gym.number_of_packages}</td>
                  <td className="border px-4 py-2">{gym.number_of_transactions}</td>
                  <td className="border px-4 py-2">{gym.time_existed}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Unlinked Gym Owners */}
      <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-xl font-bold mb-4">Gym Owners To Convert</h2>
        <div className="max-h-64 overflow-y-auto">
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="border px-4 py-2">Full Names</th>
                <th className="border px-4 py-2">Joined Date</th>
                <th className="border px-4 py-2">Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {unlinkedOwners.map((owner, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{owner.full_name}</td>
                  <td className="border px-4 py-2">{owner.joined_date}</td>
                  <td className="border px-4 py-2">{owner.phone_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SalesDashboard;