// src/components/Map/Map.js
import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

// Marker for creating a new gym (used in Create Gym)
const LocationMarker = ({ setGymLocation }) => {
  const [position, setPosition] = useState(null);

  useMapEvents({
    click(e) {
      setPosition(e.latlng);
      setGymLocation({ latitude: e.latlng.lat, longitude: e.latlng.lng });
    }
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

// New marker rendering logic for existing gyms (used in Sales Dashboard)
const ExistingGymMarkers = ({ gyms }) => {
  return gyms.map((gym, index) => (
    <Marker
      key={index}
      position={[parseFloat(gym.gym_latitude), parseFloat(gym.gym_longitude)]}
    >
      <Popup>
        <b>{gym.gym_name}</b>
        <br />
        Members: {gym.number_of_members}
        <br />
        Packages: {gym.number_of_packages}
        <br />
        Transactions: {gym.number_of_transactions}
      </Popup>
    </Marker>
  ));
};

// Main Map Component
const MapComponent = ({ setGymLocation = null, gyms = [] }) => {
  const center = gyms.length > 0
    ? [parseFloat(gyms[0].gym_latitude), parseFloat(gyms[0].gym_longitude)]
    : [-1.2921, 36.8219] // Default center if no gym data

  return (
    <MapContainer center={center} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Render markers based on the use case */}
      {setGymLocation && <LocationMarker setGymLocation={setGymLocation} />}
      {gyms.length > 0 && <ExistingGymMarkers gyms={gyms} />}
    </MapContainer>
  );
};

export default MapComponent;