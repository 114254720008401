import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BottomNavBar from "../Layout/BottomNavBar";

const GoalsCarousel = ({ goals }) => {
  return (
    <div className="p-4">
      <h2 className="text-xl font-bold text-gray-800 mb-4">Goals</h2>
      {goals && goals.length > 0 ? (
        <div className="h-[400px] overflow-y-auto space-y-4">
          {goals.map((goal) => (
            <div
              key={goal.id}
              className="relative h-[150px] rounded-lg shadow-md bg-cover bg-center"
              style={{
                backgroundImage: `url(${goal.current_goal_image_path || "https://via.placeholder.com/300"})`,
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex flex-col justify-end p-4 text-white">
                <h3 className="text-lg font-semibold truncate">{goal.goal_name}</h3>
                <p className="text-sm">{goal.goal_description}</p>
                <p className="text-xs mt-1">Deadline: {goal.deadline_in_months} months</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center">No goals set yet.</p>
      )}
    </div>
  );
};

const SummarySection = () => {
  const { gym_id } = useParams(); // Get gym_id from URL params
  const [goals, setGoals] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("Authentication failed. Please log in again.");
          setIsLoading(false);
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}homepage/${gym_id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (!response.ok) {
          setError(`Failed to fetch goals. Status: ${response.status}`);
          setIsLoading(false);
          return;
        }

        const data = await response.json();
        setGoals(data.goals || []); // Extract goals from API response
        setIsLoading(false);
      } catch (error) {
        setError("An error occurred while fetching goals.");
        setIsLoading(false);
      }
    };

    fetchGoals();
  }, [gym_id]);

  return (
    <div className="p-4 flex flex-col min-h-screen bg-gray-100">
      <div className="flex-grow">
        <h1 className="text-2xl font-bold text-center mb-4">Summary</h1>

        {/* Handle loading */}
        {isLoading && (
          <p className="text-gray-500 text-center">Loading goals...</p>
        )}

        {/* Handle errors */}
        {error && (
          <p className="text-red-500 text-center mt-4">{error}</p>
        )}

        {/* Handle no goals */}
        {!isLoading && !error && goals.length === 0 && (
          <p className="text-gray-500 text-center">No summary available.</p>
        )}

        {/* Display goals carousel when goals exist */}
        {!isLoading && !error && goals.length > 0 && (
          <GoalsCarousel goals={goals} />
        )}
      </div>

      {/* Bottom Navigation */}
      <BottomNavBar activeTab="summary" />
    </div>
  );
};

export default SummarySection;