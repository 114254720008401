import React from 'react';

const GymCard = ({ gym, onGymSelect }) => {
  console.log('GymCard received gym:', gym); // Debugging log

  return (
    <div
      className="gym-card border rounded-lg shadow-md p-4 cursor-pointer hover:shadow-lg"
      onClick={() => {
        console.log('Gym selected:', gym.gym_id); // Debugging log
        onGymSelect(gym.gym_id);
      }}
    >
      <img
        src={gym.gym_logo}
        alt={gym.gym_name}
        className="w-full h-32 object-cover rounded-md mb-4"
      />
      <h2 className="text-lg font-semibold text-center">{gym.gym_name}</h2>
    </div>
  );
};

export default GymCard;