import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { useNavigate } from 'react-router-dom';

const Classes = () => {
  const [classes, setClasses] = useState([]);
  const [newClass, setNewClass] = useState({
    class_name: '',
    class_description: '',
    class_capacity: '',
    profile_photo_name: null,
    schedules: [
      {
        day_of_week: '',
        start_time: '',
        end_time: '',
      },
    ],
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}classes/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setClasses(response.data);
    } catch (err) {
      console.error('Error fetching classes:', err);
      setError('Failed to fetch classes. Please try again later.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClass((prevClass) => ({
      ...prevClass,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setNewClass((prevClass) => ({
      ...prevClass,
      profile_photo_name: e.target.files[0],
    }));
  };

  const handleScheduleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSchedules = [...newClass.schedules];
    updatedSchedules[index][name] = value;
    setNewClass((prevClass) => ({
      ...prevClass,
      schedules: updatedSchedules,
    }));
  };

  const addSchedule = () => {
    setNewClass((prevClass) => ({
      ...prevClass,
      schedules: [...prevClass.schedules, { day_of_week: '', start_time: '', end_time: '' }],
    }));
  };

  const handleAddClass = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError(null);

    const formData = new FormData();

    formData.append('class_name', newClass.class_name);
    formData.append('class_description', newClass.class_description);
    formData.append('class_capacity', newClass.class_capacity);

    if (newClass.profile_photo_name) {
      formData.append('profile_photo_name', newClass.profile_photo_name);
    }

    formData.append('schedules', JSON.stringify(newClass.schedules));

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}classes/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setShowModal(false);
      fetchClasses();
      setNewClass({
        class_name: '',
        class_description: '',
        class_capacity: '',
        profile_photo_name: null,
        schedules: [{ day_of_week: '', start_time: '', end_time: '' }],
      });
    } catch (err) {
      console.error('Error adding new class:', err);

      if (err.response) {
        console.log('Response error data:', err.response.data);
        setError(`Failed to add class: ${err.response.data.message || 'Please check your inputs and try again.'}`);
      } else {
        setError('Failed to add class. Please check your inputs and try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClassClick = (id) => {
    navigate(`/classes/${id}`);
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Classes</h1>
          <button
            onClick={() => setShowModal(true)}
            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
          >
            Add Class
          </button>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {classes.map((cls) => (
            <div
              key={cls.id}
              className="bg-white rounded-lg shadow-lg p-4 cursor-pointer"
              onClick={() => handleClassClick(cls.id)}
            >
              {cls.profile_photo_path && (
                <img
                  src={cls.profile_photo_path}
                  alt={cls.class_name}
                  className="w-full h-48 object-cover mb-4 rounded"
                />
              )}
              <h2 className="text-xl font-semibold mb-2">{cls.class_name}</h2>
              <p className="text-gray-600 mb-4">{cls.class_description}</p>
              <p className="text-sm text-gray-500">Capacity: {cls.class_capacity}</p>
              <p className="text-sm text-gray-500 mb-4">Created by: {cls.created_by_name}</p>
            </div>
          ))}
        </div>

        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Add New Class</h3>
                <form onSubmit={handleAddClass}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Class Name</label>
                    <input
                      type="text"
                      name="class_name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newClass.class_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Class Description</label>
                    <textarea
                      name="class_description"
                      rows="3"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newClass.class_description}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Class Capacity</label>
                    <input
                      type="number"
                      name="class_capacity"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newClass.class_capacity}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Profile Photo</label>
                    <input
                      type="file"
                      name="profile_photo_name"
                      className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Schedules</label>
                    {newClass.schedules.map((schedule, index) => (
                      <div key={index} className="mb-4 p-4 bg-gray-50 rounded-md">
                        <select
                          name="day_of_week"
                          className="mb-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          value={schedule.day_of_week}
                          onChange={(e) => handleScheduleChange(index, e)}
                          required
                        >
                          <option value="">Select Day</option>
                          <option value="Monday">Monday</option>
                          <option value="Tuesday">Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thursday">Thursday</option>
                          <option value="Friday">Friday</option>
                          <option value="Saturday">Saturday</option>
                          <option value="Sunday">Sunday</option>
                        </select>
                        <input
                          type="time"
                          name="start_time"
                          className="mb-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          value={schedule.start_time}
                          onChange={(e) => handleScheduleChange(index, e)}
                          required
                        />
                        <input
                          type="time"
                          name="end_time"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                          value={schedule.end_time}
                          onChange={(e) => handleScheduleChange(index, e)}
                          required
                        />
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={addSchedule}
                      className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Add Schedule
                    </button>
                  </div>
                  {error && <p className="text-red-500 mb-4">{error}</p>}
                  <div className="mt-6 flex items-center justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowModal(false)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {isSubmitting ? 'Adding...' : 'Add Class'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Classes;