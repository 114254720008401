import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const BottomNavBar = ({ activeTab }) => {
  const navigate = useNavigate();
  const { gym_id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  // Logout Function
  const handleLogout = async () => {
    const confirmLogout = window.confirm('Are you sure you want to log out?');
    if (confirmLogout) {
      try {
        await axios.post(`${API_URL}users/auth/logout/`, {}, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        localStorage.removeItem('token');  // Clear token
        navigate('/login');  // Redirect to login page
      } catch (err) {
        console.error('Error logging out:', err);
        alert('An error occurred while logging out.');
      }
    }
  };

  return (
    <div className="fixed bottom-0 sm:bottom-5 sm:shadow-lg sm:shadow-gray-500/30 hover:shadow-md left-0 duration-300 overflow-hidden border-t border-neutral-200 sm:border z-50 w-full h-16 bg-white sm:max-w-md sm:rounded-xl inset-x-0 mx-auto">
      <div className="grid h-full grid-cols-4 mx-auto">
        {/* Home Button */}
        <button
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 gap-1 ${
            activeTab === "home" ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => {
            if (gym_id) navigate(`/homepage/${gym_id}`);
          }}
        >
          <svg className="size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M21 20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V9.48907C3 9.18048 3.14247 8.88917 3.38606 8.69972L11.3861 2.47749C11.7472 2.19663 12.2528 2.19663 12.6139 2.47749L20.6139 8.69972C20.8575 8.88917 21 9.18048 21 9.48907V20ZM19 19V9.97815L12 4.53371L5 9.97815V19H19Z"></path>
          </svg>
          <span className="text-xs">Home</span>
        </button>

        {/* Summary Button */}
        <button
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 gap-1 ${
            activeTab === "summary" ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => {
            if (gym_id) navigate(`/homepage/${gym_id}/summary`);
          }}
        >
          <svg className="size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M5 4C3.89543 4 3 4.89543 3 6V18C3 19.1046 3.89543 20 5 20H19C20.1046 20 21 19.1046 21 18V6C21 4.89543 20.1046 4 19 4H5ZM5 6H19V18H5V6ZM9 8V10H7V8H9ZM11 8H17V10H11V8ZM9 12V14H7V12H9ZM11 12H17V14H11V12Z"></path>
          </svg>
          <span className="text-xs">Summary</span>
        </button>

        {/* Billings Button */}
        <button
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 gap-1 ${
            activeTab === "billings" ? "text-orange-500" : "text-gray-500"
          }`}
          onClick={() => {
            if (gym_id) navigate(`/homepage/${gym_id}/billings`);
          }}
        >
          <svg className="size-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M6 2H18C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4C4 2.89543 4.89543 2 6 2ZM6 4V20H18V4H6ZM8 6H16V8H8V6ZM8 10H16V12H8V10ZM8 14H12V16H8V14Z"></path>
          </svg>
          <span className="text-xs">Billings</span>
        </button>

        {/* Logout Button */}
        <button
          type="button"
          onClick={handleLogout}
          className="inline-flex flex-col items-center justify-center px-5 gap-1 text-gray-500 hover:text-red-600"
        >
          <svg className="size-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a2 2 0 11-4 0v-1m0-8V7a2 2 0 114 0v1" />
          </svg>
          <span className="text-xs">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default BottomNavBar;