import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import auth from '../../api/auth';
import { Dumbbell } from 'lucide-react';

const SignUpPage = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return password.length >= minLength && specialCharRegex.test(password);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(07|01)\d{8}$/;
    return phoneRegex.test(phoneNumber);
  };

  const formatPhoneNumber = (phoneNumber) => {
    return `254${phoneNumber.slice(1)}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long and contain at least one special character');
      setIsLoading(false);
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setError('Phone number must be 10 digits long and start with 07 or 01');
      setIsLoading(false);
      return;
    }

    try {
      const response = await auth.signUp({
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: formatPhoneNumber(phoneNumber),
        password,
        terms_and_conditions: termsAndConditions,
      });
      console.log(response.data);
      NotificationManager.success('Sign up successful! Please check your email to verify your account.', 'Success');
      navigate('/signup-success');
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 400) {
        NotificationManager.error(err.response.data.error || 'An error occurred during registration.', 'Error');
      } else {
        NotificationManager.error('An error occurred during registration. Please try again.', 'Error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-100 to-gray-200 p-4">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          <div className="flex flex-col items-center mb-6">
            <div className="h-12 w-12 bg-black rounded-xl flex items-center justify-center mb-4">
              <div className="h-12 w-12 bg-black rounded-xl flex items-center justify-center mb-4">
                <Dumbbell className="h-6 w-6 text-white" />
              </div>
            </div>
            <h2 className="text-2xl font-bold">Create Your Account</h2>
            <p className="text-sm text-gray-500">Join GymStay and get tools to improve your gym members retention</p>
          </div>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="text"
                id="phoneNumber"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                maxLength={10}
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="mt-1 block w-full p-2 border rounded-md focus:ring focus:ring-gray-200"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={termsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.checked)}
                disabled={isLoading}
              />
              <label className="text-sm text-gray-700">
                I agree to the{' '}
                <Link to="/terms" className="text-blue-500 hover:underline">
                  Terms of Service
                </Link>
              </label>
            </div>
            <button
              type="submit"
              className={`w-full bg-black text-white p-2 rounded-md ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Signing up...' : 'Sign up'}
            </button>
          </form>
          <p className="mt-4 text-center text-sm text-gray-500">
            Already have an account?{' '}
            <Link to="/login" className="text-blue-500 hover:underline">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;