import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BottomNavBar from "../Layout/BottomNavBar";

const BillingsSection = () => {
  const { gym_id } = useParams(); // Extract gym_id from URL params
  const [billings, setBillings] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBillings = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("Authentication failed. Please log in again.");
          return;
        }

        // Fetch the homepage data and extract billings
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}homepage/${gym_id}/`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );

        if (!response.ok) {
          setError(`Failed to fetch billings. Status: ${response.status}`);
          return;
        }

        const data = await response.json();
        setBillings(data.billings || []);
      } catch (error) {
        setError("An error occurred while fetching billings data.");
      }
    };

    fetchBillings();
  }, [gym_id]);

  // Helper to format the billing date
  const formatBillingDate = (date) => {
    const billingDate = new Date(date);
    return billingDate.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });
  };

  if (error) {
    return (
      <div className="text-center mt-10">
        <h2 className="text-red-500">{error}</h2>
      </div>
    );
  }

  return (
    <div className="p-4 flex flex-col min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold text-center mb-4">Your Billings</h1>

      {/* Billings Table */}
      {billings.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-4 text-left">Package Name</th>
                <th className="py-3 px-4 text-left">Billing Date</th>
                <th className="py-3 px-4 text-left">Payment Status</th>
                <th className="py-3 px-4 text-left">Amount</th>
                <th className="py-3 px-4 text-left">Balance</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {billings.map((billing) => (
                <tr
                  key={billing.id}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="py-3 px-4 whitespace-nowrap">
                    {billing.package_name}
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap">
                    {formatBillingDate(billing.billing_date)}
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap">
                    <span
                      className={`py-1 px-3 rounded-full text-xs font-semibold ${
                        billing.payment_status === "Paid"
                          ? "bg-green-200 text-green-600"
                          : billing.payment_status === "Overdue"
                          ? "bg-red-200 text-red-600"
                          : "bg-orange-200 text-orange-600"
                      }`}
                    >
                      {billing.payment_status}
                    </span>
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap">
                    Ksh {parseFloat(billing.amount_due).toLocaleString()}
                  </td>
                  <td className="py-3 px-4 whitespace-nowrap">
                    {billing.payment_status === "Partial"
                      ? `Ksh ${parseFloat(billing.balance).toLocaleString()}`
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-500 text-center">No billings available.</p>
      )}

      {/* Bottom Navigation */}
      <BottomNavBar activeTab="billings" />
    </div>
  );
};

export default BillingsSection;